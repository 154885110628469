import { mapHelper } from "@/utils/common.js";
// 是否置顶，是否可以点赞，是否可以评论
const isTop = [
  {
    value: 0,
    label: "否",
  },
  {
    value: 1,
    label: "是",
  },
];

const { map: isTopMap, setOps: setIsTopOps } = mapHelper.setMap(isTop);
const status = [
  {
    value: 1,
    label: "发布",
  },
  {
    value: 3,
    label: "取消",
  },
];

const { map: statusMap, setOps: setStatusOps } = mapHelper.setMap(status);

const kindsList = [
  {
    value: "32",
    label: "资讯发布",
  },
  {
    value: "33",
    label: "树标杆",
  },
  {
    value: "34",
    label: "干实事",
  },
  {
    value: "35",
    label: "定制度",
  },
  {
    value: "32,33,34,35",
    label: "全部",
  },
];
const { map: kindsListMap, setOps: kindsListOps } = mapHelper.setMap(kindsList);
const kindsFormList = [
  {
    value: "32",
    label: "资讯发布",
  },
  {
    value: "33",
    label: "树标杆",
  },
  {
    value: "34",
    label: "干实事",
  },
  {
    value: "35",
    label: "定制度",
  },
];
const { map: kindsFormListMap, setOps: kindsFormListOps } =
  mapHelper.setMap(kindsFormList);

// 用户类别
const userType = [
  {
    value: "10",
    checked: false,
    label: "居民",
  },
  {
    value: "11",
    checked: false,
    label: "企业/商户",
  },
  // {
  //   value: "21",
  //   checked: false,
  //   label: "运营人员",
  // },
];
const { map: userTypeMap, setOps: setUserTypeOps } = mapHelper.setMap(userType);
export {
  setIsTopOps,
  isTopMap,
  userTypeMap,
  setUserTypeOps,
  statusMap,
  setStatusOps,
  kindsListMap,
  kindsList,
  kindsFormList,
};
